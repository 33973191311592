import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, Subject, throwError, timer } from 'rxjs';
import { catchError, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { HelperService } from '../helper.service';

@Injectable()
export class InterceptInterceptor implements HttpInterceptor {
  private unsubscribe$ = new Subject<void>();

  constructor(private router: Router,private helper :HelperService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if(request.url.includes('boyot-employees/login')){
      return next.handle(request);
    }else{
      return next.handle(request.clone({
        setHeaders:{'Authorization':'Bearer '+localStorage.getItem('token')}})).pipe(
          takeUntil(this.unsubscribe$),
            catchError((error)=>{
              this.helper.hidespinner()
              if (error.status == 401) {
                this.router.navigate(["auth/login"]);
                localStorage.clear();
                this.unsubscribe$.next();
                timer(0).subscribe(() => {
                  this.helper.showToastDanger("Error", error.error.message);
                });
                return throwError(error);
              } else if (error.status == 403) {
                this.unsubscribe$.next();
                timer(0).subscribe(() => {
                  this.helper.showToastDanger("Warning", error.error.message);
                });
              }     
              return throwError(error);
            }))
    }
  }
}
